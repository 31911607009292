import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'app/models/Auth/auth.service';
import { CourseGroup } from 'app/models/Coursesinfo/course-group';
import { CourseOfer } from 'app/models/Coursesinfo/course-ofert';
import { CoursesService } from 'app/models/Coursesinfo/courses.service';
import { School } from 'app/models/Coursesinfo/school';
import { Userapp } from 'app/models/Coursesinfo/user-app';
import { UserSubject } from 'app/models/Coursesinfo/user-subject';
import { Role } from 'app/models/usersInfo/role';
import { User } from 'app/models/usersInfo/user';
import { UsersService } from 'app/models/usersInfo/users.service';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'filter-bulletin',
  templateUrl: './filter-bulletin.component.html',
  styleUrls: ['./filter-bulletin.component.css']
})
export class FilterBulletinComponent implements OnInit {

  @Output()
  courseGroup = new EventEmitter<CourseGroup>();
  @Output()
  userCourse = new EventEmitter<UserSubject>();
  @Output()
  typeView = new EventEmitter<String>();
  @Output()
  courseOffer = new EventEmitter<CourseOfer>();

  public formFilters: any = {
    schoolID: 0,
    courseOfferID: 0,
    courseGroupID: 0,
    userCourseID: 0
  }

  public userCourses: User[] = []
  public schools: School[]
  public currentUser: string;
  public roles: Role[];
  public courseOffers: CourseOfer[]
  public courseGroups: CourseGroup[]

  constructor(private coursesServices: CoursesService, private authService: AuthService, private servicios: UsersService) {
    this.schools = [new School()]
    this.courseOffers = [new CourseOfer()]
    this.courseGroups = [new CourseGroup()]
  }
  @Input()
  schoolID: number
  ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    this.servicios.getRolesByUserID(parseInt(this.currentUser)).subscribe(p => {
      this.roles = p;
      this.schoolID = this.roles[0].SchoolID
      this.schoolGet()
    })
  }

  schoolGet() {
    let filter = { include: { childrenSchools: "childrenSchools" }, where: { id: this.schoolID }, order: "NameTSchool ASC" }
    this.coursesServices.getSchoolsbyFilters(JSON.stringify(filter)).subscribe(res => {
      this.schools = this.flattenArray(res);
      console.log(this.schools, "this.schools")
      this.formFilters.courseGroupID = 0
      this.formFilters.courseOfferID = 0
    });
  }
  flattenArray(arr) {
    return arr.map(item => {
      if (item.childrenSchools && item.childrenSchools.length > 0) {
        return [item, ...this.flattenArray(item.childrenSchools)];
      } else {
        return item;
      }
    }).flat();
  }
  getCourseOffers() {
    let filter = { and: [{ SchoolID: this.formFilters.schoolID }] }
    this.coursesServices.getAllCoursesOfersBySchools(JSON.stringify(filter)).subscribe(res => {
      this.courseOffers = res
      this.formFilters.courseGroupID = 0
    })
  }
  getCourseGroups() {
    let filter = { where: { and: [{ CourseOferID: this.formFilters.courseOfferID }] } }
    this.coursesServices.getCourseGroups(JSON.stringify(filter)).subscribe(res => {
      this.courseGroups = res
    })
    this.courseOffer.emit(this.courseOffers.find(x=>x.id==this.formFilters.courseOfferID))
  }


  getUserCourses() {
    
    let filter = { 
      include:["userapp"],
      where: { and: [{ CourseGroupID: this.formFilters.courseGroupID }] },
    }
    this.coursesServices.getUserCourses(JSON.stringify(filter)).subscribe(res => {
      this.userCourses = res
      console.log(this.userCourses)
    })
    this.courseGroup.emit(this.courseGroups.find(x=>x.id==this.formFilters.courseGroupID));
  }


  selectUserCourse() {
  let  emmitUserCourse = this.userCourses.find(x=>x.id==this.formFilters.userCourseID)
     this.userCourse.emit(emmitUserCourse);
  }
  selectView(typeView:string):void{
    this.typeView.emit(typeView)
  }

}
