import { Injectable } from '@angular/core';

import { Course } from './course';
import { School } from './school';

import { Subject } from './subject'
import { AuthService } from "../Auth/auth.service";
import { MidTerm } from '../midterm/midterm';
import { DateRecord } from '../daterecord/daterecord';
import { DateMidTerm } from '../midterm/datemidterm';
import { StudentSubject } from './student-subject';
import { StudentsCourseInfor } from '../usersInfo/students-course-infor';
import { TeacherSubjectInfor } from '../usersInfo/teacher-subject-infor';
import { InforCourseByDate } from '../usersInfo/infor-course-by-date';
import { roleDirectorInspectorBySchool } from '../usersInfo/roleDirectorInspectorBySchool';
import { isNullOrUndefined } from 'util';
import { ContractsInterface } from './contracts-interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
//const JSON2 = require('circular-json')
import * as moment from 'moment';
import { environment } from 'environments/environment';
@Injectable()
export class CoursesService {


  constructor(private http: HttpClient, private authService: AuthService) {
  }

  private baseUrl: string = this.authService.UrlAPI;
  private BACK_V2: string = environment.URL_BACK_V2;



  public postNotificationQueue(data: any): Observable<any> {
    const notification$ = this.http.post(`${this.baseUrl}/NotificationQueues`, data, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return notification$;
  }

  getNews(school: number): Observable<any> {
    //Filter by school and dependSchool
    const endDate = new Date(); // Current date
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 5);

    const filters = JSON.stringify({
      where: {
        and: [
          { CreateAt: { between: [startDate.toISOString(), endDate.toISOString()] } },
          { SchoolID: school },
          { IsPrivate: true },
          { isApproved: true }
        ]
      },
      order: 'id DESC',
      include: ['school']
    });

    const news$ = this.http.get(`${this.baseUrl}/Articles?filter=${filters}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return news$;
  }

  getAllTypeCourse(): Observable<any> {
    let typecourses$ = this.http
      .get(`${this.baseUrl}/TypeCourses`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return typecourses$;
  }
  getSchoolsbyFilters(filter: any): Observable<any> {
    let url = `Schools`
    if (filter) {
      url += `?filter=${filter}`
    }

    let getSchool$ = this.http
      .get(`${this.baseUrl}/${url}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return getSchool$
  }

  getTypeCourseByID(id: number): Observable<any> {
    let categorys$ = this.http
      .get(`${this.baseUrl}/TypeCourses/${id}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return categorys$;
  }

  getAllCountrie(): Observable<any> {
    let countries$ = this.http
      .get(`${this.baseUrl}/Countries`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return countries$;
  }

  getAllDepartament(): Observable<any> {
    let departaments$ = this.http
      .get(`${this.baseUrl}/Departaments`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return departaments$;
  }

  getAllTypeTraining(): Observable<any> {
    let departaments$ = this.http
      .get(`${this.baseUrl}/TypeTrainings`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return departaments$;
  }

  getAllCategory(): Observable<any> {
    let categorys$ = this.http
      .get(`${this.baseUrl}/Categories`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return categorys$;
  }

  getAllClassroomAvailabilitiesByCourseOfferID(id: number): Observable<any> {
    let classrooms$ = this.http
      .get(`${this.baseUrl}/CoursesOfers/${id}/ClassroomAvailabilitys?filter={ "include" : ["Classrooms","CourseOfers"] }`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return classrooms$;
  }




  getAllTypeCourseByCategory(id: number): Observable<any> {
    if (id != 0) {
      let categorytipe$ = this.http
        .get(`${this.baseUrl}/Categories/${id}/TypeCourses`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return categorytipe$;
    } else {
      let typecourses$ = this.http
        .get(`${this.baseUrl}/TypeCourses`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return typecourses$;
    }
  }



  getAllSchools(): Observable<any> {
    let schools$ = this.http
      .get(`${this.baseUrl}/Schools?filter={"include": ["AcademicAreas"]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return schools$;
  }
  getAllTypeClassRooms(): Observable<any> {
    let schools$ = this.http
      .get(`${this.baseUrl}/TypeClassrooms`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return schools$;
  }

  getAllTypeContracts(): Observable<any> {
    let schools$ = this.http
      .get(`${this.baseUrl}/TypeContracts`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return schools$;
  }



  //u
  getSchoolByID(id: number): Observable<School> {
    let schools = this.http
      .get(`${this.baseUrl}/Schools/${id}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return schools;
  }

  getAllMidTerm(): Observable<any> {
    let midterms$ = this.http
      .get(`${this.baseUrl}/MidTerms`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return midterms$;
  }

  getAllMidTermsBySubjectGroupID(id: number): Observable<any> {
    let midterms$ = this.http
      .get(`${this.baseUrl}/SubjectGroups/${id}/minTerms`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return midterms$;
  }






  getAllAreaAcademic(filter?:string): Observable<any> {
    let url ="/AcademicaAreas"
    if (filter)
      url+=`?filter=${filter}`
    let academicareas$ = this.http
      .get(`${this.baseUrl}${url}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return academicareas$;
  }

  getAllCourses(): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/Courses`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getAllSchoolByDepend(id: number): Observable<any> {
    let dependSchool$ = this.http
      .get(`${this.baseUrl}/Schools?filter={"where": {"and": [{"DepenSchoolID":${id}}]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return dependSchool$;
  }

  getAllCoursesBySchool(id: number, isActive?): Observable<any> {

    if (isActive == null)
      isActive = true

    let courses$;
    if (id == 0) {
      courses$ = this.http
        .get(`${this.baseUrl}/CoursesOfers?filter={"where": {"and": [{"IsActive":${isActive}}]},"include" : ["course"] }`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
    } else {
      courses$ = this.http
        .get(`${this.baseUrl}/Schools/${id}/CourseOfers?filter={"where": {"and": [{"IsActive":${isActive}}]},"include" : ["course"] }`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
    }
    return courses$;
  }

  getAllCoursesOfersBySchool(id: number): Observable<any> {
    const today = new Date();
    const courses$ = this.http
      .get(`${this.baseUrl}/Schools/${id}/CourseOfers?filter={"where": {"and": [ {"InscriptStart":{"lt": "${today}"}},{"InscriptEnd":{"gt": "${today}"}}]},"include" : ["course"] }`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return courses$;
  }
  getAllCoursesOfersBySchools(filter): Observable<any> {
    let url = `${this.BACK_V2}/CoursesOfers`
    if (!!filter)
      url = url + `?filter={"where":${filter}}`
    const coursesOfer$ = this.http
      .get(url)
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return coursesOfer$;
  }
  getCourseGroups(filter): Observable<any> {
    let url = `${this.BACK_V2}/CourseGroups`
    if (!!filter)
      url = url + `?filter=${filter}`
    const coursesOfer$ = this.http
      .get(url)
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return coursesOfer$;
  }

  getUserCourses(filter): Observable<any> {
    let url = `${this.BACK_V2}/Usercourses`
    if (!!filter)
      url = url + `?filter=${filter}`
    const coursesOfer$ = this.http
      .get(url)
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return coursesOfer$;
  }

  getUserSubjects(filter): Observable<any> {
    let url = `${this.baseUrl}/UserSubjects`
    if (!!filter)
      url = url + `?filter={"where":${filter},"include":["userapp"]}`
    const coursesOfer$ = this.http
      .get(url)
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return coursesOfer$;
  }
  getAllCoursesBySchoolByDate(id: number, year: number): Observable<any> {
    let courses$;

    courses$ = this.http
      .get(`${this.baseUrl}/Schools/${id}/CourseOfers?filter={"where": {   "Since":{ "between":["${year}-01-01","${year}-12-31"]}  }   ,"include" : ["course"] }`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return courses$;
  }


  getAllGlobalBreaksBySchoolID(id: number): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/Schools/${id}/GlobalBreakTimes`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )


  }

  getCoursesOferByEntrenamientos(idSchool: number, idTypeCourse: number): Observable<any> {
    let courses$;
    courses$ = this.http
      .get(`${this.baseUrl}/CoursesOfers/?filter={"where": {"and": [{"SchoolID":${idSchool}},{"TypeCourseID":${idTypeCourse}}]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return courses$;
  }


  getHonorRollByCourseGroupID(id: number): Observable<any> {
    let courses$;
    courses$ = this.http
      .get(`${this.baseUrl}/HonorRolls/?filter={"where": {"and": [{"SubjectGroupID":${id}}]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return courses$;
  }



  getHonorRollByCourseOfferAndSubjectID(course: number, subject: number): Observable<any> {
    let courses$
    courses$ = this.http
      .get(`${this.baseUrl}/HonorRolls/?filter={"where": {"and": [{"CourseOferID":${course}},{"SubjectID":${subject}} ]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return courses$;
  }





  getAllCoursesBySchoolNotFilter(id: number, CourseID: number): Observable<any> {
    let courses$;
    if (id == 0) {
      courses$ = this.http
        .get(`${this.baseUrl}/CourseOfers/?filter={"where": {"and": [{"CourseID":${CourseID}}]}}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
    } else {
      courses$ = this.http
        .get(`${this.baseUrl}/Schools/${id}/CourseOfers/?filter={"where": {"and": [{"CourseID":${CourseID}}]}}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
    }
    return courses$;
  }

  getAllCoursesMayaBySchool(id: number): Observable<any> {
    let courses$;
    if (id == 0) {
      courses$ = this.http
        .get(`${this.baseUrl}/Courses`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
    } else {
      courses$ = this.http
        .get(`${this.baseUrl}/Schools/${id}/Courses`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
    }
    return courses$;
  }

  createSchedule(data): Observable<any> {
    console.log(data)
    return this.http
      .post(`${this.baseUrl}/SubjectGroups/generateAlgorithm`, { data: JSON.stringify(data) }, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

  }



  newUserJobsByUserID(id: number, data): Observable<any> {
    console.log(JSON.stringify(data))
    let users$ = this.http
      .post(`${this.baseUrl}/Userapps/${id}/UserJobs`, JSON.stringify(data), { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return users$;
  }


  getUserJobsByUserID(id: number): Observable<any> {
    let users$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/UserJobs`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return users$;
  }

  getCoursesByUserID(id: number): Observable<any> {
    // {"where": {"and": [{"UserID": "3356"} ,{"IsAcepted": false}]}  , "include": {"relation": "coursesOfer","scope": { "where":{"IsActive":true}}}}
    let users$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/UserCourses?filter={"where": {"and": [{"IsAcepted": true}]}  , "include": {"relation": "coursesOfer","scope": { "where":{"IsActive":true}}}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return users$;
  }

  getRecordsOfCoursesByUserID(id: number): Observable<any> {
    // {"where": {"and": [{"UserID": "3356"} ,{"IsAcepted": false}]}  , "include": {"relation": "coursesOfer","scope": { "where":{"IsActive":true}}}}
    let users$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/UserCourses?filter={"where": {"and": [{"IsAcepted": true}]}  , "include": {"relation": "coursesOfer"}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return users$;
  }


  getUsersByCourseOfferID(id: number): Observable<any> {
    // {"where": {"and": [{"UserID": "3356"} ,{"IsAcepted": false}]}  , "include": {"relation": "coursesOfer","scope": { "where":{"IsActive":true}}}}
    let users$ = this.http
      .get(`${this.baseUrl}/UserCourses/?filter={"where": {"and": [{"CourseOferID":${id}}]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return users$;
  }

  getUsersByCourseOfferIDandCourseGroupID(id: number, idCourseGroup: number): Observable<any> {
    // {"where": {"and": [{"UserID": "3356"} ,{"IsAcepted": false}]}  , "include": {"relation": "coursesOfer","scope": { "where":{"IsActive":true}}}}
    let users$ = this.http
      .get(`${this.baseUrl}/UserCourses/?filter={"where": {"and": [{"CourseOferID":${id}} ,  {"CourseGroupID": ${idCourseGroup}  }     ]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return users$;
  }



  getCoursesByUserIDNotFilter(id: number): Observable<any> {
    let courses$ = this.http
      // .get(`${this.baseUrl}/Userapps/${id}/coursesOfers/?filter={"include":["course","TypeCourseOFer"],"order":"SchoolID asc"}`, { headers: this.getHeaders()})
      .get(`${this.baseUrl}/UserCourses/?filter={"where": {"and": [{"UserID": ${id}} ]}  , "include": {"relation": "coursesOfer"}}`, { headers: this.getHeaders() })


      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }


  getAllHonorRollCoursesOferActiveByCourseOferID(id: number): Observable<any> {
    let courses$ = this.http
      // .get(`${this.baseUrl}/Userapps/${id}/coursesOfers/?filter={"include":["course","TypeCourseOFer"],"order":"SchoolID asc"}`, { headers: this.getHeaders()})
      .get(`${this.baseUrl}/HonorRollCoursesOferActives?filter={ "include":["Subjects"] ,    "where": {"and": [{"CourseOferID": ${id}}]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getallUserCoursesByCoursesOfers(id: number): Observable<any> {
    let filter = `?filter={
      "include": [ "coursesOfer",
      {
        "relation":  "userapp", "scope": { "include":  
                      [   "ProfesionalProfile" ,  "AcademicInfos", 
                        { "relation":  "UserDocuments", "scope":  {"include":
                                                                              [ "TypeDocuments" ]} 
                        }
                      ] 
                }}
    ]}`;
    return this.http
      .get(`${this.baseUrl}/CoursesOfers/${id}/UserCourses${filter}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

  }

  getallCourseOferByCourseID(id: number): Observable<any> {


    return this.http
      .get(`${this.baseUrl}/Courses/${id}/CourseOfer`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

  }


  getAllCoursesByTypeCourse(id: number): Observable<any> {
    let courses$;
    if (id == 0) {
      courses$ = this.http
        .get(`${this.baseUrl}/CoursesOfers`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
    } else {
      courses$ = this.http
        .get(`${this.baseUrl}/TypeCourses/${id}/CourseOfers`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
    }
    return courses$;
  }

  //u
  getAllAcademicAreasBySchool(id: number): Observable<any> {
    let modules$
    if (id == 0) {
      modules$ = this.http
        .get(`${this.baseUrl}/AcademicaAreas`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
    } else {
      modules$ = this.http
        .get(`${this.baseUrl}/Schools/${id}/AcademicAreas`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
    }
    return modules$;
  }


  getAllSubjectsByCourseBox(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/Courses/${id}/Subjects/?filter={"include": ["AcademicaArea"]}`, { headers: this.getHeaders() })

      //.get(`${this.baseUrl}/Courses/${id}/Subjects/?filter={"include": ["AcademicaArea"], "limit":7}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getSubjectsGroupByAreaByProfessor(idArea: number, idProfessor: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/AcademicaAreas/${idArea}/Subject?filter={"include": ["subjectgroup"], "limit":9}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getAllSubjectsByAreaByCourse(idarea: number, idcourse: number): Observable<any> {
    if (idarea == 0 && idcourse == 0) {
      let courses$ = this.http
        .get(`${this.baseUrl}/Subjects?filter={"include": ["AcademicaArea"]}   `, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }
    if (idarea == 0) {

      let courses$ = this.http
        .get(`${this.baseUrl}/Subjects/?filter={"include": ["AcademicaArea"],"where": {"and": [{"CourseID":${idcourse}} ]}}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }
    if (idcourse == 0) {

      let courses$ = this.http
        .get(`${this.baseUrl}/Subjects/?filter={"include": ["AcademicaArea"], "where": {"and": [{"AcademicAreaID":${idarea}}]}}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }
    let courses$ = this.http
      .get(`${this.baseUrl}/Subjects/?filter={"include": ["AcademicaArea"], "where": {"and": [{"AcademicAreaID":${idarea}} ,{"CourseID":${idcourse}} ]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getAllSubjectsByCourse(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/Courses/${id}/Subjects`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getAllSubjectsByArea(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/AcademicaAreas/${id}/Subject/`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  //CONSULTAS PARA LOS INFORMES - INICIO
  getAllCourseIsActiveBySchool(id: number): Observable<Course> {
    var anyo = new Date().getFullYear();
    let IsActiveBySchool$;
    IsActiveBySchool$ = this.http
      .get(`${this.baseUrl}/InforCourse_Ds/count?where={"and" :[{"SchoolID":${id}},{"IsActive":true},{"Since": {"gte": "${anyo}-01-01T00:00:00.000Z"}}]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return IsActiveBySchool$;
  }

  countAllUserCoursesByCourseOferID(id: number): Observable<any> {

    return this.http
      .get(`${this.baseUrl}/UserCourses/count?where={"and" :[{"CourseGroupID":${id}} , {"IsAcepted":true}]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )


  }


  getAllCourseByCategory1(id: number): Observable<Course> {
    var anyo = new Date().getFullYear();
    let Category1$;
    Category1$ = this.http
      .get(`${this.baseUrl}/InforCourse_Ds/count?where={"and":[{"SchoolID":${id}},{"NameCategoryCourse": "Superior"},{"Since": {"gte": "${anyo}-01-01T00:00:00.000Z"}}]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return Category1$;
  }

  getAllRoleDirectorInspectorBySchool(schoolId: number, role: number): Observable<roleDirectorInspectorBySchool> {
    let role$;
    role$ = this.http
      .get(`${this.baseUrl}/roleDirectorInspectorBySchools?filter={"where":{"and":[{"SchoolID":${schoolId}},{"roleId":${role}}]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return role$;
  }

  getAllCourseByCategory2(id: number): Observable<Course> {
    var anyo = new Date().getFullYear();
    let Category2$;
    Category2$ = this.http
      .get(`${this.baseUrl}/InforCourse_Ds/count?where={"and":[{"SchoolID":${id}},{"NameCategoryCourse": "Militar"},{"Since": {"gte": "${anyo}-01-01T00:00:00.000Z"}}]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return Category2$;
  }

  getAllCourseByCategory3(id: number): Observable<Course> {
    var anyo = new Date().getFullYear();
    let Category3$;
    Category3$ = this.http
      .get(`${this.baseUrl}/InforCourse_Ds/count?where={"and":[{"SchoolID":${id}},{"NameCategoryCourse": "Continuada"},{"Since": {"gte": "${anyo}-01-01T00:00:00.000Z"}}]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return Category3$;
  }

  getAllCourseByCategory4(id: number): Observable<Course> {
    var anyo = new Date().getFullYear();
    let Category4$;
    Category4$ = this.http
      .get(`${this.baseUrl}/InforCourse_Ds/count?where={"and":[{"SchoolID":${id}},{"NameCategoryCourse": "Entrenamiento"},{"Since": {"gte": "${anyo}-01-01T00:00:00.000Z"}}]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return Category4$;
  }

  getAllUserByRole2(id: number): Observable<Course> {
    let Role2$;
    Role2$ = this.http
      .get(`${this.baseUrl}/UserInformes/count?where={"SchoolID":${id}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return Role2$;
  }

  getAllUserAcepted(id: number): Observable<Course> {
    let Acepted$;
    Acepted$ = this.http
      .get(`${this.baseUrl}/UserInformes/count?where={"and":[{"SchoolID":${id}},{"IsAceptedUser":true}]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return Acepted$;
  }

  getAllStudentCourseOferBySchool(id: number): Observable<StudentsCourseInfor[]> {
    var anyo = new Date().getFullYear();
    let UserInfor$;
    UserInfor$ = this.http
      .get(`${this.baseUrl}/StudentsCourseInfors?filter={"where": {"and" :[{"SchoolID":${id}},{"Since": {"gte": "${anyo}-01-01T00:00:00.000Z"}}]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return UserInfor$;
  }

  getAllTeacherSubjectBySchool(id: number): Observable<TeacherSubjectInfor[]> {
    var anyo = new Date().getFullYear();
    let TeacherSub$;
    TeacherSub$ = this.http
      .get(`${this.baseUrl}/TeacherSubjectInfors?filter={"where": {"and" :[{"SchoolID":${id}},{"DateStart": {"gte": "${anyo}-01-01"}}]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return TeacherSub$;
  }

  getAllCoursesDatesBySchoolByCategory1(SchoolId: number, CategoryId: number): Observable<InforCourseByDate[]> {
    var anyo = new Date().getFullYear();
    let TeacherSub$;
    TeacherSub$ = this.http
      .get(`${this.baseUrl}/InforCourseByDates?filter={"order":"Date ASC", "where": {"and" :[{"SchoolID":${SchoolId}},{"CategoryID":${CategoryId}},{"Date": {"gte": ${anyo}}}]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return TeacherSub$;
  }


  getUserappsByCC(autosearch: string): Observable<any> {
    let providers$ = this.http
      .get(`${this.baseUrl}/Userapps?filter={ "include":[ "UserDocuments" ], "limit": 5,"where": {"and": [{"CedocEmail": {"like": "${autosearch}%25"}}]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return providers$;
  }




  //CONSULTAS PARA LOS INFORMES - FIN

  //SE RECIBE EL ID DE EL AREA ACADEMICA  Y ENTREGA LOS USUARIOS DE ESA AERA
  getAllUserAppsByAcademicArea(id: number): Observable<any> {
    var filter = `{ "include": 
    {"relation":  "UserJobs",
        "scope" : { "where":  {  "or": [ 
                                          {"and" : [ { "isAcepted":  true} , { "JobOferID": ${id} } ] }
                                        ] 
                              }
                  } 
    },
    "order": "LastName1 ASC" }`;
    let courses$ = this.http
      .get(`${this.baseUrl}/AcademicaAreas/${id}/userapps/?filter=${filter}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }



  //ES LO MISMO QUE LLEGA DE "MODULO" DEL ENDPOINT SUBJECTS 
  getAcademicAreasByID(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/AcademicaAreas/${id}/`, { headers: this.getHeaders() })
      //.get(`${this.baseUrl}/UserCourses/CountStudentsByOfer?CourseOferID=${id}`, { headers: this.getHeaders()})

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }



  getAcademicAreasByUserID(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/academicaAreas`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getSubjectByProfesorID(id: number): Observable<any> {
    let courses$ = this.http
      // .get(`${this.baseUrl}/Userapps/${id}/subjectgroup?filter={"include":["subjects","minTerms","coursesgroup", "CourseOffers"]}`, { headers: this.getHeaders() })
      .get(`${this.baseUrl}/Userapps/${id}/subjectgroup?filter={"include":["subjects","minTerms","coursesgroup", "CourseOffers","userapp"]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getSubjectByProfesorIDByDate(id: number, date: string): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/subjectgroup?filter={ "where":{ "DateEnd":{ "gte" : "${date}" } },  "include":["subjects","minTerms","coursesgroup"]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }




  getCourseofersByCourseGroupID(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/CourseGroups/${id}/Courses`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }



  getSubjectGroupsNew(filter: string): Observable<any> {
    let url = "/SubjectGroups"
    if (!!filter)
      url+= `?filter=${filter}`
    let courses$ = this.http
      .get(`${this.BACK_V2}${url}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }
  getSubjectGroups(id: number, schoolID: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/SubjectGroups?filter={"include":["subjects","minTerms","coursesgroup","userapp", "CourseOffers"]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }


  putSubjectByProfesorID(id: number, fk: number, course: Course): Observable<any> {
    let courses$ = this.http
      .put(`${this.baseUrl}/Userapps/${id}/subjectgroup/${fk}`,
        JSON.stringify(course),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;

  }

  putMidTermByProfesorID(id: number, fk: number, course: MidTerm): Observable<any> {
    let courses$ = this.http
      .put(`${this.baseUrl}/SubjectGroups/${id}/minTerms/${fk}`,
        JSON.stringify(course),
        { headers: this.putHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;

  }

  getAllDaterecordByMidterm(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/MidTerms/${id}/dateRecords?filter={"include": ["midTerm"],"order":"id DESC"}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getAllDaterecordsWithNoExtensionBySubjectGroupID(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/SubjectGroups/${id}/dateRecords?filter={"where"  : { "IsExtension": false}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }


  getCountStudentsByOfer(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/UserCourses/CountStudentsByOfer/${id}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }


  getCourseOferByID(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/CoursesOfers/${id}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }


  getCoursesOfers(filter:string): Observable<any> {
    let url= "/CoursesOfers"
    if (!!filter)
      url+=`?filter=${filter}`
    let courses$ = this.http
      .get(`${this.baseUrl}${url}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }




  getUserCoursesByUserID(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/UserCourses`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }



  getAllCourseBySchoolAndTypeCourse(idschool: number, idtypecourse: number, isActive?: boolean): Observable<any> {
    let actived = true
    if (isActive)
      actived = isActive

    if (idschool == 0 && idtypecourse == 0) {
      let courses$ = this.http
        .get(`${this.baseUrl}/CoursesOfers/?filter={"where": {"and": [{"IsActive":${actived}} ]},"include":["course","TypeCourseOFer",{"relation":"UserCourses","scope": {"fields": ["CourseOferID"]}}],"order":"SchoolID asc"}`, { headers: this.getHeaders() })
        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }
    if (idschool == 0) {

      let courses$ = this.http
        .get(`${this.baseUrl}/CoursesOfers/?filter={"where": {"and": [{"TypeCourseID":${idtypecourse}},{"IsActive":${actived}} ]},"include":["course","TypeCourseOFer"]}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }
    if (idtypecourse == 0) {

      let courses$ = this.http
        .get(`${this.baseUrl}/CoursesOfers/?filter={"where": {"and": [{"SchoolID":${idschool}},{"IsActive":${actived}}]},"include":["course","TypeCourseOFer"]}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }

    let courses$ = this.http
      .get(`${this.baseUrl}/CoursesOfers/?filter={"where": {"and": [{"SchoolID":${idschool}} ,{"TypeCourseID":${idtypecourse}},{"IsActive":${actived}} ]},"include":["course","TypeCourseOFer"]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  //u
  getAllCoursesMayaBySchoolAndTypeCourses(idschool: number, idtypecourse: number): Observable<any> {

    if (idschool == 0 && idtypecourse == 0) {
      let courses$ = this.http
        .get(`${this.baseUrl}/Courses/?filter={"where": {"and": [{"IsActive":true} ]},"include":["School","TypeCourse"],"order":"SchoolID asc"}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }
    if (idschool == 0) {

      let courses$ = this.http
        .get(`${this.baseUrl}/Courses/?filter={"where": {"and": [{"TypeCourseID":${idtypecourse}},{"IsActive":true} ]},"include":["School","TypeCourse"]}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }
    if (idtypecourse == 0) {

      let courses$ = this.http
        .get(`${this.baseUrl}/Courses/?filter={"where": {"and": [{"SchoolID":${idschool}},{"IsActive":true}]},"include":["School","TypeCourse"]}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }
    let courses$ = this.http
      .get(`${this.baseUrl}/Courses/?filter={"where": {"and": [{"SchoolID":${idschool}} ,{"TypeCourseID":${idtypecourse}},{"IsActive":true} ]},"include":["School","TypeCourse"]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getAllCoursesMayaBySchoolByType(idschool: number, idtypecourse: number): Observable<any> {

    if (idschool == 0 && idtypecourse == 0) {
      let courses$ = this.http
        .get(`${this.baseUrl}/Courses/?filter={"order":"SchoolID asc"}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }
    if (idschool == 0) {

      let courses$ = this.http
        .get(`${this.baseUrl}/Courses/?filter={"where": {"and": [{"TypeCourseID":${idtypecourse}} ]},"order":"NameCourse asc"}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }
    if (idtypecourse == 0) {

      let courses$ = this.http
        .get(`${this.baseUrl}/Courses/?filter={"where": {"and": [{"SchoolID":${idschool}}]},"order":"NameCourse asc"}`, { headers: this.getHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return courses$;
    }

    let courses$ = this.http
      .get(`${this.baseUrl}/Courses/?filter={"where": {"and": [{"SchoolID":${idschool}} ,{"TypeCourseID":${idtypecourse}}]},"order":"NameCourse asc"}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }



  getStudentsByCourseOferByID(id: number): Observable<any> {
    let courses$ = this.http


      //.get(`${this.baseUrl}/CoursesOfers/${id}/userapps/?filter={"order": "LastName1 ASC","include": ["UserCourses"]}`, { headers: this.getHeaders() })
      .get(`${this.baseUrl}/CoursesOfers/${id}/userapps/?filter={"order": "LastName1 ASC","include": [{ "relation": "UserCourses", "scope": { "where": {"and": [{"IsAcepted": true},{"CourseOferID": ${id}} ]}}} ]}`, { headers: this.getHeaders() })


      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getSubjectsByCourseMaya(idcourse: number): Observable<any> {
    let subjects$ = this.http
      .get(`${this.baseUrl}/Subjects/?filter={"where": {"and": [{"CourseID":${idcourse}}]}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return subjects$;
  }


  getAllBreakTimesByCourseOferByID(id: number): Observable<any> {
    let courses$ = this.http
      //.get(`${this.baseUrl}/UserCourses?filter={"where": {"and": [{"CourseOferID": ${id}} ]}}`, { headers: this.getHeaders()})
      .get(`${this.baseUrl}/CoursesOfers/${id}/BreakTimes`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getAllActiveBreakTimesByCourseOferByID(id: number): Observable<any> {
    let courses$ = this.http
      //.get(`${this.baseUrl}/UserCourses?filter={"where": {"and": [{"CourseOferID": ${id}} ]}}`, { headers: this.getHeaders()})
      .get(`${this.baseUrl}/CoursesOfers/${id}/BreakTimes?filter={ "where": {"and": [{"isAvailable":true}]} }`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getAllActiveGlobalBreakTimesBySchoolID(id: number, range): Observable<any> {
    let courses$ = this.http
      //.get(`${this.baseUrl}/UserCourses?filter={"where": {"and": [{"CourseOferID": ${id}} ]}}`, { headers: this.getHeaders()})
      .get(`${this.baseUrl}/Schools/${id}/GlobalBreakTimes?filter={ "where": {"and": [{"isAvailable":true} , {"StarTime":  { "gte": "${range[0]}"  } }   ,  {"EndTime":  { "lte": "${range[1]}"  } }    ]  }}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }






  getAllCourseGroupsByCourseOferByID(id: number): Observable<any> {
    let courses$ = this.http
      //.get(`${this.baseUrl}/UserCourses?filter={"where": {"and": [{"CourseOferID": ${id}} ]}}`, { headers: this.getHeaders()})
      .get(`${this.baseUrl}/CoursesOfers/${id}/coursegroups/?filer={"order": "name ASC"  }`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getCountGroupsByCourseOferByID(id: number): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let courses$ = this.http
      .get(`${this.baseUrl}/CoursesOfers/${id}/coursegroups/count`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  patchUserCourseById(id: number, data): Observable<any> {
    return this
      .http
      .patch(`${this.baseUrl}/UserCourses/${id}`,
        JSON.stringify(data),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )



  }

  patchUserRecordById(id: number, data): Observable<Course> {


    let responsePost = this
      .http
      .patch(`${this.baseUrl}/UserRecords/${id}`,
        JSON.stringify(data),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }

  patchClassRoom(data): Observable<Course> {
    let responsePost = this
      .http
      .patch(`${this.baseUrl}/Classrooms`,
        JSON.stringify(data),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }

  patchUserJobContract(data): Observable<ContractsInterface> {
    let responsePost = this
      .http
      .patch(`${this.baseUrl}/UserJobsContracts`,
        JSON.stringify(data),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }
  patchSchoolReqs(data): Observable<any> {
    let responsePost = this
      .http
      .patch(`${this.baseUrl}/SchoolReqs`,
        JSON.stringify(data),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }


  deleteGlobalBreakTimeByID(id: number): Observable<ContractsInterface> {
    let responsePost = this
      .http
      .delete(`${this.baseUrl}/GlobalBreakTimes/${id}`,
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }

  patchGlobalBreakTimes(data): Observable<ContractsInterface> {
    let responsePost = this
      .http
      .patch(`${this.baseUrl}/GlobalBreakTimes`,
        JSON.stringify(data),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }


  patchBreakTimes(data): Observable<ContractsInterface> {
    let responsePost = this
      .http
      .patch(`${this.baseUrl}/BreakTimes`,
        JSON.stringify(data),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }



  deleteClassRoom(id): Observable<Course> {
    let responsePost = this
      .http
      .delete(`${this.baseUrl}/Classrooms/${id}`,
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }


  getAllClassRoomsBySchoolID(SchollID: number): Observable<any> {
    let responsePost = this
      .http
      .get(`${this.baseUrl}/Schools/${SchollID}/Classrooms`,
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }

  getAllSchoolReqsBySchoolID(id: number): Observable<any> {
    let response = this
      .http
      .get(`${this.baseUrl}/Schools/${id}/SchoolReqs`,
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return response;
  }

  getAllUserJobsContractBySchoolID(SchollID: number, date?: string): Observable<any> {
    if (date != null) {
      date = moment(date).utc().format("YYYY-MM-DD")
      let responsePost = this
        .http
        .get(`${this.baseUrl}/Schools/${SchollID}/UserJobsContracts?filter={"include": ["TypeContracts","Userapps"], "where": {"and": [{"isActive":true} , { "StartContract": { "lte": "${date}"} }    ,  { "EndContract": { "gte": "${date}"} }          ]} }`,
          { headers: this.putHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return responsePost;

    } else {
      let responsePost = this
        .http
        .get(`${this.baseUrl}/Schools/${SchollID}/UserJobsContracts?filter={"include": ["TypeContracts","Userapps"]}`,
          { headers: this.putHeaders() })

        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )
      return responsePost;
    }
  }

  getAllUserJobsBySchoolID(SchollID: number): Observable<any> {


    let responsePost = this
      .http
      .get(`${this.baseUrl}/Schools/${SchollID}/UserJobs?filter={"include":   {"relation":  "userapp",   "scope" : { "include": [ "UserDocuments"]  }}     , "where": {"and": [{"isActive":true}   ]} }`,
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;


  }




  createCourse(course: Course, schoolID: number): Observable<Course> {

    course.SchoolID = schoolID;
    let responsePost = this
      .http
      .patch(`${this.baseUrl}/courses`,
        JSON.stringify(course),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }

  createSubject(subject: Subject, CourseID: number): Observable<Subject> {

    subject.CourseID = CourseID;
    let responsePost = this
      .http
      .patch(`${this.baseUrl}/Subjects`,
        JSON.stringify(subject),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }

  createCourseOfer(course: Course): Observable<Course> {

    let responsePost = this
      .http
      .patch(`${this.baseUrl}/CoursesOfers`,
        JSON.stringify(course),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }

  createSubjectGroup(course): Observable<Course> {
    console.debug(course)
    let responsePost = this
      .http
      .patch(`${this.baseUrl}/SubjectGroups`,
        JSON.stringify(course),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }

  UpdateSubjectGroup(subject: Subject, id): Observable<Course> {

    let responsePost = this
      .http
      .put(`${this.baseUrl}/SubjectGroups/${id}`,
        JSON.stringify(subject),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }

  patchSubjectGroup(subject: Subject, id): Observable<Course> {

    let responsePost = this
      .http
      .patch(`${this.baseUrl}/SubjectGroups/${id}`,
        JSON.stringify(subject),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }



  CountScheduleSubjects(id): Observable<Subject> {

    let responsePost = this
      .http
      .get(`${this.baseUrl}/SubjectGroups/${id}/count`,
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }


  getSubjectGroupsByCourseGroupID(id: number): Observable<any> {

    let responsePost = this
      .http
      .get(`${this.baseUrl}/SubjectGroups?filter={"include": ["subjects","userapp"] , "where": {"and": [{"CourseGroupID":${id}}  ]}}`,
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }

  getSubjectGroupByCourseGroupIDAndSubjectID(idcroursegroup: number, idSubject: number): Observable<Subject> {
    let responsePost = this
      .http
      .get(`${this.baseUrl}/SubjectGroups?filter={"include": ["subjects","userapp"] , "where": {"and": [{"CourseGroupID":${idcroursegroup}} ,{"SubjectID":${idSubject}}  ]}}`,
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }





  getScheduleSubjects(id): Observable<any> {

    let responsePost = this
      .http
      .get(`${this.baseUrl}/SubjectGroups/${id}/scheduleSubjects`,
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }



  sendEmailProfessor(id: number, subject: string, nota: string, studentname: string): Observable<Subject> {

    var data = {
      idUserapp: id,
      nameSubject: subject,
      numberAccording: nota,
      studentName: studentname,

    }


    let responsePost = this
      .http
      .post(`${this.baseUrl}/UserRecords/sendEmailAccordingStudent`,
        JSON.stringify(data),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }


  CreateScheduleSubjects(subject: Subject, id): Observable<Subject> {

    let responsePost = this
      .http
      .post(`${this.baseUrl}/SubjectGroups/${id}/scheduleSubjects`,
        JSON.stringify(subject),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }




  getScheduleSubjectByCourseIDAndSubjectsID(CoursegroupID: number, subjectsID: any[]): Observable<any> {




    let responsePost = this
      .http
      .get(`${this.baseUrl}/SubjectGroups?filter={"where": {"or" :  ${JSON.stringify(subjectsID)}  , "and": [ {"CourseGroupID": ${CoursegroupID}}] },"order":"SubjectID asc"}`,
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;



  }


  DeleteScheduleSubjectByID(id: number): Observable<Subject> {

    let responsePost = this
      .http
      .delete(`${this.baseUrl}/ScheduleSubjects/${id}`,
        { headers: this.putHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return responsePost;


  }

  DeleteClassroomAvailabilitysByCoursesOferID(id: number, idClassAvaliable: number): Observable<Subject> {
    let responsePost = this
      .http
      .delete(`${this.baseUrl}/CoursesOfers/${id}/ClassroomAvailabilitys/${idClassAvaliable}`,
        { headers: this.putHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return responsePost;
  }

  DeleteClassroomAvailabilitysByID(id: number,): Observable<Subject> {
    let responsePost = this
      .http
      .delete(`${this.baseUrl}/ClassroomAvailabilities/${id}`,
        { headers: this.putHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return responsePost;

  }



  PatchClassroomAvailabilitysByCoursesOferID(data): Observable<any> {

    let responsePost = this
      .http
      .patch(`${this.baseUrl}/ClassroomAvailabilities`,
        JSON.stringify(data),
        { headers: this.putHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }



  PatchScheduleSubjects(subject: Subject): Observable<Subject> {
    console.debug("llego al patch la siguiente informacion")
    console.debug(subject)
    console.debug("decodificado" + JSON.stringify(subject))
    let responsePost = this
      .http
      .patch(`${this.baseUrl}/ScheduleSubjects`,
        JSON.stringify(subject),
        { headers: this.putHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }





  UpdateScheduleSubjects(subject: Subject, SubjectGoupID, ScheduleSubjectID): Observable<Subject> {

    let responsePost = this
      .http
      .put(`${this.baseUrl}/SubjectGroups/${SubjectGoupID}/scheduleSubjects/${ScheduleSubjectID}/`,
        JSON.stringify(subject),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }




  EditCourseGroupsByID(id: number, info): Observable<any> {
    return this
      .http
      .patch(`${this.baseUrl}/CourseGroups/${id}`,
        JSON.stringify(info),
        { headers: this.putHeaders() });
  }



  CreateCourseGroups(id: number, name: string, startDayTime: string, endDayTime: string): Observable<any> {
    return this
      .http
      .put(`${this.baseUrl}/CourseGroups/`,
        {
          "CourseOferID": id,
          "name": name,
          "StartDayTime": startDayTime,
          "EndDayTime": endDayTime
        },
        { headers: this.putHeaders() });
  }

  getUsersByCourseOfferHasCourseGroup(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/CoursesOfers/${id}/userapps?filter={ "include":  {  "relation": "UserCourses"  , "scope" : {  "where":  { "and": [{ "CourseOferID": ${id}}  , { "IsAcepted": true }] }               }   } }`, { headers: this.getHeaders() })

      //.get(`${this.baseUrl}/CoursesOfers/${id}/userapps?filter={ "include":  {  "relation": "UserCourses"  , "scope" : {  "where": { "CourseGroupID": { "neq": "null"}}  }   } }`, { headers: this.getHeaders() })
      //.get(`${this.baseUrl}/CoursesOfers/${id}/userapps/?filter={ "include":  ["UserCourses" ] }`, { headers: this.getHeaders()})


      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getUsersByCourseOfferHasNotCourseGroup(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/CoursesOfers/${id}/userapps/?filter={ "include":  {  "relation": "UserCourses"  , "scope" : {  "where": { "CourseGroupID": null}}     } }`, { headers: this.getHeaders() })

      // .get(`${this.baseUrl}/CoursesOfers/${id}/userapps/?filter={"where": {"and": [{"CourseOferID": 1 } , { "CourseGroupID" :  null  } ]}}`, { headers: this.getHeaders()})

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getdatemidtermsBySchoolID(id: number): Observable<DateMidTerm> {


    //decir que la fecha de datestart de subjectouup    esta en el rango de fechas de datemidterm, utilizar el error si no hay fecha es porque no hay horarios
    let courses$ = this.http
      .get(`${this.baseUrl}/DateMidTerms/?filter={"where": {"and": [{"SchoolID":${id}}]}}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getdatemidtermsBySchoolID2(id: number): Observable<any> {


    //decir que la fecha de datestart de subjectouup    esta en el rango de fechas de datemidterm, utilizar el error si no hay fecha es porque no hay horarios
    let courses$ = this.http
      .get(`${this.baseUrl}/DateMidTerms/?filter={"where": {"and": [{"SchoolID":${id}}]}}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }


  UpdtateAssociationUserCourse(valores): Observable<any> {


    console.debug("llego la variable" + JSON.stringify(valores));
    return this
      .http
      .patch(`${this.baseUrl}/UserCourses/${valores.id}/`,
        JSON.stringify(valores),
        { headers: this.putHeaders() });
  }


  patchMidTerm(midterm: MidTerm): Observable<MidTerm> {
    return this
      .http
      .patch(`${this.baseUrl}/MidTerms`,
        JSON.stringify(midterm),
        { headers: this.putHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
  }


  saveDateRecord(daterecord: DateRecord): Observable<DateRecord> {
    return this
      .http
      .post(`${this.baseUrl}/DateRecords`,
        JSON.stringify(daterecord),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
  }

  patchDateRecord(daterecord: DateRecord): Observable<DateRecord> {
    return this
      .http
      .patch(`${this.baseUrl}/DateRecords`,
        JSON.stringify(daterecord),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
  }


  getEventsCalendarByGroupID(subject, range: any[]): Observable<any> {
    //console.debug("estoy en el servicio y me llego estos datos:" + JSON.stringify(subject) + " y el rango " + range)
    //console.debug(subject)
    var CourseGroupID = parseInt(subject.CourseGroupID)
    var StartDate = range[0].toISOString()
    var EndDate = range[1].toISOString()

    let courses$ = this.http
      .get(`${this.baseUrl}/ScheduleSubjects/?filter={"include": {"relation":  "SubjectGroups",   "scope" : { "include": [ "coursesgroup", "subjects","userapp"]  ,  "where": { "CourseGroupID":  ${CourseGroupID} }  }  },   "where": {"and": [{"HourStart":  {      "lte":  "${EndDate}"  , "gte":  "${StartDate}"   } }]}   } `, { headers: this.getHeaders() })
      //.get(`${this.baseUrl}/CoursesOfers/${id}/userapps/?filter={ "include":  ["UserCourses" ] }`, { headers: this.getHeaders()})


      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )


    return courses$;
  }


  getAceptedUserCoursesByUserID(id: number): Observable<any> {

    let courses$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/UserCourses?filter={ "include":  {  "relation": "coursesOfers"  , "scope" : {  "include": [ "course"]     ,  "where": { "IsAcepted": true }  }   } } `, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }









  getEventsCalendarProfessorByUserID(id: number, range: any[]): Observable<any> {
    //console.debug("estoy en el servicio y me llego estos datos:" + JSON.stringify(subject) + " y el rango " + range)
    //console.debug(subject)

    var StartDate = range[0].toISOString()
    var EndDate = range[1].toISOString()

    let courses$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/subjectgroup/?filter={ "include": ["subjects" ,"coursesgroup", {     "relation":  "schedulesubjects",      "scope": {  "where": {"and": [{"HourStart":  {      "lte":  "${EndDate}"  , "gte":  "${StartDate}"   } }]}          }       } 	  ] 	   	  }`, { headers: this.getHeaders() })

      //  .get(`${this.baseUrl}/Userapps/${id}/subjectgroup/?filter={ "include": ["subjects" ,"coursesgroup", {     "relation":  "schedulesubjects",      "scope": {                  "where": {"and": [{"HourStart":  {      "lte":  "${EndDate}"  , "gte":  "${StartDate}"   } }]}          }       } 	  ] 	   	  }`, { headers: this.getHeaders() })
      //.get(`${this.baseUrl}/CoursesOfers/${id}/userapps/?filter={ "include":  ["UserCourses" ] }`, { headers: this.getHeaders()})


      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )


    return courses$;
  }


  getEventsCalendarStudentByUserID(id: number, range: any[]): Observable<any> {
    //console.debug("estoy en el servicio y me llego estos datos:" + JSON.stringify(subject) + " y el rango " + range)
    //console.debug(subject)

    var StartDate = range[0].toISOString()
    var EndDate = range[1].toISOString()

    let courses$ = this.http
      .get(`${this.baseUrl}/ScheduleStudents/?filter={"where": {"and": [ {"HourStart":  { "between":  [ "${StartDate}" , "${EndDate}"] } }, 					{"UserID":${id}}   					] 			} }`, { headers: this.getHeaders() })
      //.get(`${this.baseUrl}/CoursesOfers/${id}/userapps/?filter={ "include":  ["UserCourses" ] }`, { headers: this.getHeaders()})


      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )


    return courses$;
  }






  getUserSubjectsBySubjectGroupID(id: number): Observable<any> {
    let courses$ = this.http
      .get(`${this.baseUrl}/UserSubjects/?filter={"where": {"and": [{"SubjectGroupID":${id}}]}} `, { headers: this.getHeaders() })


      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )


    return courses$;
  }



  getUserSubjectsBySubjectGroupsIDs(filer): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/UserSubjects/?filter=${filer}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost$;
  }




  getSubjectsByUserIDRange(id: number, range: any[]): Observable<any> {
    var StartDate = range[0].toISOString()
    var EndDate = range[1].toISOString()

    let courses$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/UserSubjects/?filter={"include": {"relation":  "subjectGroup",   "scope" : { "include": ["subjects"  ] ,  "where": {"and": [{"DateEnd":  {  "between":  ["${StartDate}", "${EndDate}" ]   } }]}}}}`, { headers: this.getHeaders() })


      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }


  getSubjectsByUserID(id: number): Observable<any> {


    let courses$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/UserSubjects/?filter={"include": {"relation":  "subjectGroup",   "scope" : { "include": ["subjects" ,"coursesgroup","minTerms","dateRecords"] }}}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;
  }

  getUserRecordsByMidtermsFilter(filter): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/UserRecords?filter=${filter}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost$;
  }





  assignUserSubject(object: Subject): Observable<any> {
    let courses$ = this.http
      .post(`${this.baseUrl}/UserSubjects/assignUserSubject`,
        JSON.stringify(object),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return courses$;


  }



  createUserSubjects(usersubjects: Subject): Observable<Subject> {

    let responsePost = this
      .http
      .patch(`${this.baseUrl}/UserSubjects`,
        JSON.stringify(usersubjects),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }


  patchMidTermsByID(id: number, data): Observable<any> {
    let responsePost = this
      .http
      .patch(`${this.baseUrl}/MidTerms/${id}`,
        JSON.stringify(data),
        { headers: this.putHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }

  saveUserRecord(UserRecordID: number, userrecord: Subject): Observable<any> {


    console.log('userrecord to send: ', userrecord);

    if (!UserRecordID) {
      let responsePost = this
        .http
        .patch(`${this.baseUrl}/UserRecords`,
          JSON.stringify(userrecord),
          { headers: this.putHeaders() })
      return responsePost;

    }
    else {
      let responsePost = this
        .http
        .patch(`${this.baseUrl}/UserRecords/${UserRecordID}`,
          JSON.stringify(userrecord),
          { headers: this.putHeaders() })
      return responsePost;
    }

  }


  getAllStudentRecord(id): Observable<any> {
    const filter = JSON.stringify({
      where: {
        and: [
          { SubjectGroupID: id },
          { isAvailable: true }
        ]
      },
      include: {
        relation: "RecordMidTerm",
      }
    })

    let studentrecord$ = this.http
      .get(`${this.baseUrl}/StudentSubjects?filter=${filter}`, { headers: this.getHeaders() })
      .pipe(
        map(mapRecords),
        catchError(err => this.authService.errorMessages(err))
      )
    return studentrecord$;
  }


  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }

  putHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }
  getCourseByID(id: number): Observable<Course> {
    let course$ = this.http
      .get(`${this.baseUrl}/Courses/${id}?filter={"include":["Subjects"]}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )

    return course$;
  }

  get(id: number): Observable<any> {
    let course$ = this.http
      .get(`${this.baseUrl}/CoursesOfers/${id}`, { headers: this.getHeaders() })
      .pipe(
        map(mapCourses),
        catchError(err => this.authService.errorMessages(err))
      )
    return course$;
  }

  getCoursesByID(id: number): Observable<Course> {
    let course$ = this.http
      .get(`${this.baseUrl}/CoursesOfers/${id}?filter={"include":[{"course":"qualitativeDescriptions"}]}`, { headers: this.getHeaders() })

      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
    return course$;
  }
  save(course: Course): Observable<any> {
    return this
      .http
      .put(`${this.baseUrl}/CoursesOfers/1`,
        JSON.stringify(course),
        { headers: this.putHeaders() });
  }

  public updateUserSubject(userSubjectId: number, absences: number = 0) {
    const data = { Absences: absences };

    const filters = JSON.stringify({ include: [{ userRecord: 'midTerm' }] })

    return this.http.patch(
      `${this.baseUrl}/UserSubjects/${userSubjectId}`, data, { headers: this.getHeaders() }).pipe(
        switchMap((e: any) => this.http.get(`${this.baseUrl}/UserSubjects/${e.id}?filter=${filters}`))
      )
  }
}

function mapCourses(response): Course[] {
  //throw new Error('ups! Force choke!');
  // The response of the API has a results
  // property with the actual results
  return response.map(toPerson)
}

function toPerson(r: any): Course {
  let course = <Course>({

    id: r.id,
    NameCourseOfer: r.NameCourseOfer
  });
  return course;
}



function mapCourse(response: Response): Course {
  // toPerson looks just like in the previous example
  return toPerson(response.json());
}








function mapRecords(response): StudentSubject[] {
  console.log(response);

  return response.map(toRecord)
}

function toRecord(r: any): StudentSubject {
  var note1Id;
  var note1;
  var absences1;
  var remedialGrade1;
  var isAccording1;
  var professorNote1;

  var note2Id;
  var note2;
  var absences2;
  var remedialGrade2;
  var isAccording2;
  var professorNote2;

  var note3Id;
  var note3;
  var absences3;
  var remedialGrade3;
  var isAccording3;
  var professorNote3;

  var note4Id;
  var note4;
  var absences4;
  var remedialGrade4;
  var isAccording4;
  var professorNote4;

  var note5Id;
  var note5;
  var absences5;
  var remedialGrade5;
  var isAccording5;
  var professorNote5;

  var note6Id;
  var note6;
  var absences6;
  var remedialGrade6;
  var isAccording6;
  var professorNote6;

  if (isNullOrUndefined(r.RecordMidTerm) == false) {
    if (r.RecordMidTerm.length > 0) {
      var num1 = r.RecordMidTerm.find(x => x.MidTermNum == "1");
      console.log(num1);

      if (num1) {
        note1Id = num1.id;
        note1 = num1.ParcialRecord;
        absences1 = num1.absences
        isAccording1 = num1.IsAccording;
        remedialGrade1 = num1.remedialGrade;
        professorNote1 = num1.professorNote;
      }
    }



    if (r.RecordMidTerm.length > 1) {

      var num2 = r.RecordMidTerm.find(x => x.MidTermNum == "2");
      if (num2) {
        note2Id = num2.id;
        note2 = num2.ParcialRecord;
        absences2 = num2.absences;
        isAccording2 = num2.IsAccording;
        remedialGrade2 = num2.remedialGrade;
        professorNote2 = num2.professorNote;

      }
    }


    if (r.RecordMidTerm.length > 2) {
      var num3 = r.RecordMidTerm.find(x => x.MidTermNum == "3");
      if (num3) {
        note3Id = num3.id;
        note3 = num3.ParcialRecord;
        absences3 = num3.absences;
        isAccording3 = num3.IsAccording;
        remedialGrade3 = num3.remedialGrade;
        professorNote3 = num3.professorNote;
      }
    }

    if (r.RecordMidTerm.length > 3) {
      var num4 = r.RecordMidTerm.find(x => x.MidTermNum == "4");
      if (num4) {
        note4Id = num4.id;
        note4 = num4.ParcialRecord;
        absences4 = num4.absences;
        isAccording4 = num4.IsAccording;
        remedialGrade4 = num4.remedialGrade;
        professorNote4 = num4.professorNote;
      }
    }

    if (r.RecordMidTerm.length > 4) {
      var num5 = r.RecordMidTerm.find(x => x.MidTermNum == "5");
      if (num5) {
        note5Id = num5.id;
        note5 = num5.ParcialRecord;
        absences5 = num5.absences;
        isAccording5 = num5.IsAccording;
        remedialGrade5 = num5.remedialGrade;
        professorNote5 = num5.professorNote;
      }
    }

    if (r.RecordMidTerm.length > 5) {
      var num6 = r.RecordMidTerm.find(x => x.MidTermNum == "6");
      if (num6) {
        note6Id = num6.id;
        note6 = num6.ParcialRecord;
        absences6 = num6.absences;
        isAccording6 = num6.IsAccording;
        remedialGrade6 = num6.remedialGrade;
        professorNote6 = num6.professorNote;
      }
    }


    let course = ({
      id: r.id,
      Names: r.Names,
      LastNames: r.LastNames,
      Document: r.Document,
      CedulaMil: r.CedulaMil,
      FinalRecord: r.FinalRecord,
      SubjectGroupID: r.SubjectGroupID,
      TotalAbsences: r.Absences || 0,
      CedocEmail: r.CedocEmail,
      email: r.email,
      UserID: r.UserID,

      Note1Id: note1Id,
      Note1: note1,
      Absences1: absences1,
      RemedialGrade1: remedialGrade1,
      IsAccording1: isAccording1,
      professorNote1: professorNote1,

      Note2Id: note2Id,
      Note2: note2,
      Absences2: absences2,
      RemedialGrade2: remedialGrade2,
      IsAccording2: isAccording2,
      professorNote2: professorNote2,

      Note3Id: note3Id,
      Note3: note3,
      Absences3: absences3,
      RemedialGrade3: remedialGrade3,
      IsAccording3: isAccording3,
      professorNote3: professorNote3,

      Note4Id: note4Id,
      Note4: note4,
      Absences4: absences4,
      RemedialGrade4: remedialGrade4,
      IsAccording4: isAccording4,
      professorNote4: professorNote4,

      Note5Id: note5Id,
      Note5: note5,
      Absences5: absences5,
      RemedialGrade5: remedialGrade5,
      IsAccording5: isAccording5,
      professorNote5: professorNote5,

      Note6Id: note6Id,
      Note6: note6,
      Absences6: absences6,
      IsAccording6: isAccording6,
      RemedialGrade6: remedialGrade6,
      professorNote6: professorNote6,
      isWithdrawn: r.isWithdrawn
    });

    return course;
  }


}


function mapRecord(response: Response): StudentSubject {
  return toRecord(response.json());
}


