import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CoursesService } from 'app/models/Coursesinfo/courses.service';
import { Subject } from 'app/models/Coursesinfo/subject';
import { UserSubject } from 'app/models/Coursesinfo/user-subject';
import { SubjectGroup } from 'app/models/usersInfo/subjectgroup';
import { User } from 'app/models/usersInfo/user';
import { UserCourse } from 'app/models/usersInfo/usercourse';

@Component({
  selector: 'view-bulletin-by-user',
  templateUrl: './view-bulletin-by-user.component.html',
  styleUrls: ['./view-bulletin-by-user.component.scss']
})
export class ViewBulletinByUser implements OnInit, OnChanges {

  @Input()
  midTermNumSelected: number;
  @Input()
  userCourseSelected: UserCourse;
  @Input()
  subjectsByCourseAndSemester: Array<Subject>;
  @Input()
  academicAreas: Array<any>;
  @Input()
  subjectGroups: Array<SubjectGroup>;

  public userSelected: User
  renderData: any[];
  constructor(private coursesServices: CoursesService) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

    console.log("changes", changes)
    if (changes.userCourseSelected && changes.userCourseSelected.currentValue) {
      this.userSelected = { ...changes.userCourseSelected.currentValue.userapp }
    }
    if (changes.midTermNumSelected && changes.midTermNumSelected.currentValue) {
      this.midTermNumSelected = { ...changes.midTermNumSelected.currentValue }
    }
    if (changes.subjectsByCourseAndSemester && changes.subjectsByCourseAndSemester.currentValue) {
      this.subjectsByCourseAndSemester = [...changes.subjectsByCourseAndSemester.currentValue]
      this.setUserData()
    }
    if (changes.academicAreas && changes.academicAreas.currentValue) {
      this.academicAreas = [...changes.academicAreas.currentValue]
    }
    if (changes.subjectGroups && changes.subjectGroups.currentValue) {
      this.subjectGroups = [...changes.subjectGroups.currentValue]
      this.setUserData()
    }
  }


  setUserData() {

    let tempData = [...this.academicAreas]
    this.renderData = []


    console.log("tempData", tempData)

    tempData.forEach(area => {
      area.subjects = this.subjectsByCourseAndSemester.filter(x => x.AcademicAreaID == area.id)
      area.subjects.forEach(subject=>{
        subject.subjectGroup = this.subjectGroups.find(x=>x.SubjectID == subject.id)
      })

    });
    this.renderData = tempData.sort((a, b) => a.NameArea.localeCompare(b.NameArea))


     console.log("SE VA A RENDERIZAR", this.renderData)




  }

}
